import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { Fa6Module } from '../fa/fa6.module';
import { PromanTableModule } from '../table';
import { PipesModule } from '../shared/pipes/pipes.module';
import { Store } from '@ngrx/store';
import { AiFriendService } from '../services/ai-friend.service';
import { Entity } from '../services/entity.service';
import { PromanPaddingDirective } from '../shared/directives/proman-padding.directive';
import { PromanButtonComponent } from '../button';
import { PromanTextSimpleComponent } from '../text-simple';
import { CurrUser, TableConfig } from '../interfaces/object-interfaces';
import { isDefinedNotNull } from '../utils';
import { getCurrUser } from '../store/curr-user';
import moment from 'moment';

declare interface ConversationMessage {
  message: string;
  personId: number;
  timestamp?: string;
}

@Component({
  selector: 'pro-ai-friend-conversation',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    Fa6Module,
    PromanTableModule,
    PipesModule,

    PromanTextSimpleComponent,
    PromanButtonComponent,

    PromanPaddingDirective,
  ],
  template: `
    <div fxLayout="row">
      <pro-table [config]="tableConfig" [timeStamp]="timestamp"></pro-table>
      
      @if (selectedConversation()) {
        <div fxFlex="50">
          <div proPadding class="message-container" fxLayout="column">
            @for (message of messageHistory; track $index) {
              <article fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <fa [name]="message.personId ? 'user' : 'brain-circuit'"></fa>
                  <div [innerHTML]="message.timestamp"></div>
                </div>
                <p fxFlex="80" proPadding [innerHTML]="message.message"></p>
              </article>
            }
          </div>

          @if (checkPerson()) {
            <div proPadding fxLayout="row">
              <pro-text-simple [value]="prompt" [config]="{ label: 'prompt', type: 'textarea' }"
                               (onChange)="setPrompt($event)"></pro-text-simple>
              <pro-btn icon="message-question" theme="accent" [disabled]="!prompt" (onClick)="sendPrompt()"></pro-btn>
            </div>
          }
        </div>
      }
    </div>
  `,
  styles: `
      .message-container {
          overflow-y: scroll;
      }
  `
})

export class AiFriendConversationComponent implements OnInit {
  messageHistory: ConversationMessage[] = [];
  authorId: number;
  currUser: CurrUser;
  selectedConversation = signal(0);
  prompt: string;
  tableConfig: TableConfig
  timestamp: number;

  constructor(
    private AiFriend: AiFriendService,
    private Entity: Entity,
    private store: Store,
  ) {
    this.store.select(getCurrUser).subscribe((value) => this.currUser = value);
  }

  ngOnInit() {
    this.setTableConfig();
  }

  setTableConfig() {
    this.tableConfig = {
      entity: 'ai_conversation',
      tableId: 'ai_conversations',
      addButton: {
        acl: null,
        callback: this.newConversation,
      },
      fields: [
        {
          name: 'created_by',
          key: 'createdBy',
          getValue: (row) => row.createdBy.name,
          filter: {
            type: 'autocomplete',
            entity: 'person',
          }
        },
        {
          name: 'created_at',
          key: 'createdAt',
          formatter: 'dateTime',
          formatterConfig: '_datetime_js',
        }
      ],
      quickFilters: [
        {
          icon: 'user-check',
          key: 'createdBy',
          value: [this.currUser.person.id],
        }
      ],
      rowButtons: [
        {
          theme: 'accent',
          icon: 'edit',
          acl: null,
          callback: this.loadConversation,
          show: (row: any) => row.createdBy.id === this.currUser.person.id,
        },
        {
          theme: 'accent',
          icon: 'eye',
          acl: null,
          callback: this.loadConversation,
          show: (row: any) => row.createdBy.id !== this.currUser.person.id,
        },
        {
          theme: 'warn',
          icon: 'times',
          acl: null,
          callback: this.deleteConversation,
          show: (row: any) => row.createdBy.id === this.currUser.person.id,
        }
      ],
      extraParameters: {
        partialJoin: {
          'createdBy': ['id', 'name'],
        }
      }
    }
  }

  setPrompt = (value: string) => this.prompt = value;

  sendPrompt() {
    if (isDefinedNotNull(this.prompt)) {
      this.messageHistory.push({ personId: this.currUser.person.id, message: this.prompt });
      this.AiFriend.askFriend(this.prompt, this.selectedConversation() !== -1 ? this.selectedConversation() : undefined).then((result: { answer: string, id: number }) => {
        this.selectedConversation.set(result.id);
        let answer = result.answer;
        answer = answer.replace(/\n/g, '<br>');
        answer = answer.replace(/\*\*\s/g, '</b> ');
        answer = answer.replace(/\*\*/g, '<b>');
        this.prompt = null;
        this.messageHistory.push({ personId: 0, message: answer });
        this.refresh();
      });
    }
  }

  refresh = () => this.timestamp = new Date().getTime();

  newConversation = ()=> {
    this.selectedConversation.set(-1);
    this.messageHistory = [];
    this.authorId = this.currUser.person.id;
    this.refresh();
  }

  checkPerson = () => this.authorId === this.currUser.person.id;

  loadConversation = (row: any) => {
    this.selectedConversation.set(row.id);
    this.authorId = row.createdBy.id;
    this.messageHistory = row.conversation.map((msg: ConversationMessage) => ({ ...msg, timestamp: moment.unix(+msg.timestamp).format('YYYY-MM-DD HH:mm:ss').replace(' ', '<br>') })).map((msg: ConversationMessage) => {
      msg.message = msg.message.replace(/\n/g, '<br>').replace(/\*\*\s/g, '</b> ').replace(/\*\*/g, '<b>');
      return msg;
    });
    this.refresh();
  }

  deleteConversation = (row: any) => {
    if (row.id === this.selectedConversation()) {
      this.selectedConversation.set(0)
      this.messageHistory = [];
      this.authorId = row.createdBy.id;
    }
    this.Entity.get('ai_conversation').remove({ id: row.id }).then(() => this.refresh());
  }
}
